import React, { useEffect, useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { gql, useQuery, useLazyQuery, useMutation } from '@apollo/client'
import Navigation from './Navigation';
import Header from './Header';
import Footer from './Footer';
import './Content.css';
import DataTable from '../Common/DataTable';
import { Button, Form, Input, Spin,Alert } from 'antd';
import Constants, { roles } from '../Constants';

const ResendEmail = () => {

    const [tableData, setTableData] = useState([]);
    const [editable, setEditable] = useState();
    const [disable, setDisable] = useState(false);
    const [hidden, setHidden] = useState(localStorage.getItem(Constants.userRole))
    const [form] = Form.useForm();    
    const [alertMessage, setAlertMessage] = useState()

    const resetState = () => {
            setTimeout(() => {
                setAlertMessage()
            }, 3000)
    }


    const GET_ALL_ORDERS = gql`
    query {
        getAllOrder {
          funded
          status
          id
          createdAt
          s3SignedFileUrl
          individualPayment {
            paymentType
            projectNumber
            paymentAmount
            customerEmail
            customerFirstName
            customerLastName
            trackingNumber
            projectNumber
            installationAddress1
            installationAddress2
            installationCity
            installationState
            installationZip
            mailingAddress1
            mailingAddress2
            mailingCity
            mailingState
            mailingZip
            phone
            trackingNumber
            memo
            status
            reason
            approvedAtTime
            id
            createdAt
            updatedAt
            redeemed
          }
        }
      }`




    const UPDATE_EMAIL = gql`
    mutation reminderMail($id:ID!, $email:String!){
        reminderMail(id: $id, email: $email) {
          message
        }
    }`


    const [updateEmail] = useMutation(UPDATE_EMAIL)


    const [getOrdersOnClick, { loading: getOrdersOnClickLoading, err, dataGet }] = useLazyQuery(GET_ALL_ORDERS, {
        onCompleted(dataGet) {
            const _IndividualPayArr = dataGet.getAllOrder;
            setTableData([])
            let createIndividualPayRow = {}
            let IndividualPayRecordTableData = []
            _IndividualPayArr.map((_IndividualPay, index) => {
                if (_IndividualPay.funded === true) {
                    _IndividualPay.individualPayment.map((_individual, index) => {
                        createIndividualPayRow = {
                            key: _individual.id,
                            customerFirstName: _individual.customerFirstName,
                            customerLastName: _individual.customerLastName,
                            customerEmail: _individual.customerEmail,
                            status: _individual.status,
                            id: _individual.id,
                            phone: _individual.phone,
                            paymentAmount: `$ ${ _individual.paymentAmount}`,
                            paymentType: _individual.paymentType,
                            redeemed: _individual.redeemed,
                            trackingNumber: _individual.trackingNumber,
                            projectNumber: _individual.projectNumber,
                        }
                        IndividualPayRecordTableData.push(createIndividualPayRow)
                    })
                }
            })
            setTableData([...IndividualPayRecordTableData])
        },
        onError(err) {
            console.log('onerror', err);
        },
        fetchPolicy: 'network-only'
    })


    useEffect(() => {
        getOrdersOnClick();
    }, [])

    const updateEmailHandler = async (id, email) => {
        try {
            const res = await updateEmail({
                variables: {
                    id: id,
                    email: email
                }
            })
            if (res) {
                setAlertMessage(() => { 
                    window.scrollTo({top:0,left:0,behaviour:"smooth"});
                    return <Alert type="success" message={`Email sent successfully!!`}></Alert> })
                resetState()
    
            }
        } catch (err) {
            setAlertMessage(() => { 
                window.scrollTo({top:0,left:0,behaviour:"smooth"});
                return <Alert type="error" message={err.message}></Alert>})
            resetState()
        }
    }


    const QUERY_RESENDEMAIL = gql`
    query GetIndividualPaymentBySearch($searchString: String!) {
            getIndividualPaymentWithOrderBySearch(searchString: $searchString) {
              paymentType
              paymentAmount
              customerEmail
              customerFirstName
              customerLastName
              trackingNumber
              projectNumber
              id
              phone
              redeemed
              order {
                funded
                fundedAt
                status
                id
                createdAt
                s3SignedFileUrl
                reportTotal
                filename
                orderId
              }
            }
       }`


    const [getSearch, { loading: getSearchLoading, error, data }] = useLazyQuery(QUERY_RESENDEMAIL, {
        onCompleted(data) {
            const _IndividualPayArr = data.getIndividualPaymentWithOrderBySearch;
            setTableData([])
            let createIndividualPayRow = [];
            let individualPayRecord = {};
            _IndividualPayArr.map((_IndividualPay, index) => {

                if (_IndividualPay.order.length > 0) {

                    _IndividualPay.order.map((record, index) => {

                        if (record.funded == true) {

                            individualPayRecord = {
                                key: _IndividualPay.id,
                                customerFirstName: _IndividualPay.customerFirstName,
                                customerLastName: _IndividualPay.customerLastName,
                                customerEmail: _IndividualPay.customerEmail,
                                status: _IndividualPay.status,
                                id: _IndividualPay.id,
                                phone: _IndividualPay.phone,
                                projectNumber: _IndividualPay.projectNumber,
                                trackingNumber: _IndividualPay.trackingNumber,
                                paymentAmount: `$ ${_IndividualPay.paymentAmount}`,
                                paymentType: _IndividualPay.paymentType,
                                redeemed: _IndividualPay.redeemed,
                            }

                            createIndividualPayRow.push(individualPayRecord);
                        }
                    })
                }

            })
            setTableData([...createIndividualPayRow])
        },
        onError(err) {
            console.log('onerror', err);
        },
        fetchPolicy: 'network-only'
    })




    const columns = [

        {
            title: 'First Name',
            dataIndex: 'customerFirstName',
            key: 'customerFirstName',
            fixed: 'left'
        },
        {
            title: 'Last Name',
            dataIndex: 'customerLastName',
            key: 'customerLastName',
            fixed: 'left'
        },
        {
            title: 'Email',
            dataIndex: 'customerEmail',
            key: 'email',
            render: (text, record) => {
                if (editable === record.key) {

                    return (
                        <>
                            <Form.Item
                                name={'customerEmail'}>
                                <Input />
                            </Form.Item>
                        </>
                    )
                } else {
                    return (
                        <>
                            {text}
                        </>
                    )
                }
            }
        },
        {
            title: 'Phone Number',
            dataIndex: 'phone',
            key: 'phnumber',
        },
        {
            title: 'Project Number',
            dataIndex: 'projectNumber',
            key: 'projectNumber',
        },
        {
            title: 'Tracking Number',
            dataIndex: 'trackingNumber',
            key: 'trackingNumber',
        },
        {
            title: 'Amount',
            dataIndex: 'paymentAmount',
            key: 'amount',
        },
        {
            title: 'Payment Type',
            dataIndex: 'paymentType',
            key: 'paymentType',
        },
        {
            title: 'Action',
            render: (text, record) => {
                return (
                    <>
                        <Row>
                            {(!record.redeemed) ? (<Col hidden={hidden === roles.BPS_ADMIN ? true : false} >
                                <Button type='link' disabled={disable} onClick={() => {
                                    setEditable(record.key);
                                    setDisable(true)
                                }}>
                                    update email
                                </Button>
                            </Col>) : ""}
                            {(!record.redeemed) ? (<Col>
                                <Button type='link' onClick={() => {
                                    let { customerEmail } = form.getFieldValue()
                                    if (customerEmail == undefined) {
                                        customerEmail = record.customerEmail
                                    }
                                    updateEmailHandler(record.id, customerEmail)
                                    setEditable(null)
                                    setDisable(false)
                                    getOrdersOnClick();
                                }}>
                                    Resend Email
                                </Button>
                            </Col>) : ""}
                        </Row>

                    </>
                )

            }
        },
    ];

    return (
        <>
            <section className='vh-fill'>
                <Navigation />

                <Container className='my-5 py-4'>
                    <Row>
                        <Col>
                            <h4>Resend Email</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Input.Search allowClear className='w-25' onSearch={(e) => {
                            getSearch({ variables: { searchString: e } })
                        }} />
                    </Row>
                    <Row className="w-50 alertmessage m-1">
                        {alertMessage}
                    </Row>
                    <Row>
                        <Form form={form}>
                            {
                                getOrdersOnClickLoading || getSearchLoading ? <Spin className='mt-5 d-flex justify-content-center' size='large'></Spin> : <DataTable showCheck={false} columnData={columns} tableData={tableData} scrollX={2300} scrollY={500} />
                            }
                        </Form>
                    </Row>
                </Container>
            </section>
            <Footer />
        </>
    );
}

export default ResendEmail;