import React, { useState } from 'react';
import { Form, Image, Input, Alert } from 'antd';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { gql, useLazyQuery, useMutation } from '@apollo/client'
import './Content.css';
import './Navigation.css';

const ForgotPassword = () => {


    const [variable, setVariable] = useState();
    const [emailVal, setEmailVal] = useState()
    const [userId, setUserId] = useState();
    const [form] = Form.useForm();
    const [alertMessage, setAlertMessage] = useState()

    const resetState = () => {
        setTimeout(() => {
            setAlertMessage()
        }, 3000)
    }

    const sendPasswordMail = gql`
    mutation ForgotPwdMail($email: String!) {
        forgotPwdMail(email: $email) {
          message
        }
    }`


    const [sendPassword] = useMutation(sendPasswordMail);

    const handleFormSubmit = async (val) => {
        try {
            const email = val.email
            const res = await sendPassword({
                variables: { email: email }

            })
            if (res) {

                setAlertMessage(() => { return <Alert type="success" message={`Mail Sent Successfully !!`}></Alert> })
                resetState()
            }
            else {
                setAlertMessage(() => { return <Alert type="warning" message={`please check your email !!`}></Alert> })
                resetState()
            }
        }
        catch (err) {
            setAlertMessage(() => { return <Alert type="error" message={err.message}></Alert> })
            resetState()
        }

        form.resetFields();
    }

    return (
        <section>
            <Container className='py-2'>
                <a href="/login"><img src="/images/Dahon_Cropped_260x.jpg" className="nav-logo" /></a>
            </Container>
            <Container className='header mb-5'>
                <Row className='p-4 justify-content-center'>
                    <Col md={4} className='header-box'>
                        <Row className='bg-light p-4 border-bottom text-center'>
                            <Col>
                                <h2>Forgot Password</h2>
                            </Col>
                        </Row>
                        <Row className='p-4'>
                            <Col>
                                <Form form={form} layout="vertical" name="dynamic_rule" onFinish={(values) => handleFormSubmit(values)}>
                                    <Row className="justify-content-center">
                                        <Col lg={10}>
                                            <Form.Item
                                                name="email"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input your username',
                                                    },
                                                    {
                                                        type: "email",
                                                        message: 'Please enter valid email format'
                                                    },
                                                ]}
                                                label="Email">
                                                <Input placeholder="Please input your email" onChange={(e) => (
                                                    setEmailVal(e.target.value))} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-center alertmessage mb-1">
                                        <Col lg={10}>
                                            {alertMessage}
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-center text-center pt-4">
                                        <Col lg={12}>
                                            <Form.Item>
                                                <Button type="submit" className="bps-primary-button" >Send Password</Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col>

                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default ForgotPassword;