import React, { useEffect, useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { Button, Spin, Alert } from 'antd'
import Navigation from './Navigation';
import Header from './Header';
import Footer from './Footer';
import './Content.css';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client'
import DataTable from '../Common/DataTable';
import { useLocation } from "react-router";
import { currentPath } from '../Constants';

const Fund = (props) => {
    const [alertMessage, setAlertMessage] = useState()

    const [tableData, setTableData] = useState([]);
    const location = useLocation();
 
    const resetState = () => {
         setTimeout(() => {
             setAlertMessage()
         }, 3000)
     }
 
    const path = location.pathname;
    
    const getApprovedPayment = useQuery(props.GET_APPROVED_PAYMENTS);
    const [updateTable, { load, err, data }] = useLazyQuery(props.GET_APPROVED_PAYMENTS, {
        fetchPolicy: 'network-only'
    });
    const [fund] = useMutation(props.FUND)
    
    useEffect(() => {
        if (!getApprovedPayment.loading) {
            const _IndividualPayArr = path == currentPath.fundOrder ? getApprovedPayment.data.getAllOrder : getApprovedPayment.data.getAllFundReport;
            setTableData([])
        
            _IndividualPayArr.map((_IndividualPay, index) => {
                let createIndividualPayRow = {
                    key:index,
                    orderId: _IndividualPay.orderId,
                    funded:_IndividualPay.funded,
                    fundedAt:(_IndividualPay.fundedAt===null)? "":_IndividualPay.fundedAt,
                    status:_IndividualPay.status,
                    id:_IndividualPay.id,
                    createdAt:_IndividualPay.createdAt,
                    s3SignedFileUrl: _IndividualPay.s3SignedFileUrl,
                    orderTotal:_IndividualPay.reportTotal,
                    reportTotal:_IndividualPay.reportTotal,
                    reportId:_IndividualPay.reportId,
                }
                setTableData(oldArr => [...oldArr, createIndividualPayRow])
            })
        }
    }, [getApprovedPayment])

    const fundOrderHandler = async (id) => {
        try {
            const res = await fund({
                variables: {
                    id
                }
            })

            if (res) {
                updateTable({ variables: { id: id } })
            }
        } catch (err) {
            setAlertMessage(() => {
                window.scrollTo({top:0,left:0,behaviour:"smooth"}); 
                return <Alert type="error" message={err.message}></Alert>})
            resetState()
        }
    }


    const columns = [
        (path == currentPath.fundOrder)?
        {
            title:'Order Id',
            dataIndex:'orderId',
            key:'orderId',
            fixed:'left',
        }
        :
        {
            title:'Report Id',
            dataIndex:'reportId',
            key:'reportId',
            fixed:'left'
        },
        {
            title: 'Date Created',
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
        (path == currentPath.fundOrder)?
        {
            title: 'Order Total',
            dataIndex: 'orderTotal',
            key: 'orderTotal',
            render:((_,record)=>{
                return <span>${record.orderTotal}</span>
            })
        }
        :
        {
            title:'Report Total',
            dataIndex:'reportTotal',
            key:'reportTotal',
            render:((_,record)=>{
                return <span>${record.reportTotal}</span>
            })
        },
        {
            title: 'Download Link',
            dataIndex: 's3SignedFileUrl',
            key: 's3SignedFileUrl',
            render: (_, record) => {
                return (
                    <>
                        <a href={`${record.s3SignedFileUrl}`} download>Download</a>
                    </>
                )
            }
        },{
            title:"Funded Date",
            dataIndex:"fundedAt",
            key:"fundedAt",
            render:((_,record)=>{
                   const date=record.fundedAt.split('T');
             return(<span>{date[0]}</span>);
            })
        },
        {
            title: 'Action',
            render: ((_, record, prevRecord) => {
                if (record.funded == true) {
                    return (
                        <>
                            <Button disabled type='link'>
                                Funded
                            </Button>
                        </>
                    )
                } else {
                    return (
                        <>
                            <Button type='link' onClick={() => fundOrderHandler(record.id)}>
                                Fund {path == currentPath.fundOrder? 'Order' : 'Report'}
                            </Button>
                        </>
                    )
                }

            })
        },
    ];

    return (
        <>
            <section className='vh-fill'>
                <Navigation />
              
                <Container className='my-5 py-4'>
                    <Row>
                        <Col>
                            <h4>{path == currentPath.fundOrder? 'Invoice Order' : 'Fund Report'}</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="alertmessage m-1">
                            {alertMessage}
                        </Col>
                    </Row>
                    <Row className='mb-2'>
                        {
                            getApprovedPayment.loading? <Spin className='mt-5 d-flex justify-content-center' size='large'></Spin> :  <DataTable showCheck={false} columnData={columns} tableData={tableData} scrollX={1300} scrollY={500}/>
                        }
                    </Row>
                </Container>
            </section>
            <Footer />
        </>
    );
}

export default Fund;