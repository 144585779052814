import React from 'react';
import Uploader from './Uploader';
import {uploadModelNumberFile, modelNumberSub} from '../Common/queries';



const ModelNumberUploader = ()=>{
    return (
        <>
            <Uploader  mutation={uploadModelNumberFile} subscription={modelNumberSub} subData={'rebateAmountFileUpdateStatus'}/>
        </>
    )
}


export default ModelNumberUploader;