import { gql } from "@apollo/client";

// upload serial number query
export const uploadFileMutation = gql`
  mutation Mutation($file: Upload!) {
    serialNumberUploader(file: $file)
  }
`;

export const fileSubscription = gql`
  subscription Subscription {
    serialNumberFileUpdateStatus {
      type
      message
    }
  }
`;

//upload model number query

export const uploadModelNumberFile = gql`
  mutation Mutation($file: Upload!) {
    rebateAmountUploader(file: $file)
  }
`;

export const modelNumberSub = gql`
  subscription Subscription {
    rebateAmountFileUpdateStatus {
      type
      message
    }
  }
`;
