import React, { useEffect, useState } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { gql, useLazyQuery, useMutation } from '@apollo/client'
import { Form, Input, message, Button, Select, Popconfirm, Spin, Alert } from 'antd';
import Footer from "../Footer";
import Header from "../Header";
import Navigation from "../Navigation";
import DataTable from "../../Common/DataTable";
import Constants, { roles as constantRoles, rolesName } from "../../Constants";


const UserTable = () => {

    const [form] = Form.useForm();
    const { Option } = Select;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [tableData, setTableData] = useState();
    const [editable, setEditable] = useState();
    const [rolesUser, setRoles] = useState([]);
    const [roleId, setRoleId] = useState();
    const [totalPages, setTotalPages] = useState({});

    const [alertMessage, setAlertMessage] = useState()

    const resetState = () => {
        setTimeout(() => {
            setAlertMessage()
        }, 3000)
    }

    const GET_USERS = gql`
    query getAllUsers($sortCreatedAt:Int, $page:Int){
            getAllUsers(sortCreatedAt: $sortCreatedAt, page: $page) {
            users {
                username
                name
                email
                id
                phone
                isActive
                createdAt
                registeredDate
                userRole{
                roleName
                }
            }
            totalPages
            totalDocs
            currentPage
            limit
        }
      }`

    const QUERY_SEARCHBYNAME = gql`
query searchUserByString($searchString:String!)
{
    searchUserByString(searchString: $searchString) {
      name
      email
      id
      userRole {
        isActive
        roleName
        id
      }
      isActive
      createdAt
      registeredDate
      username
    }
  }

`

    const [getSearch, { loading: getSearchLoading, error, data }] = useLazyQuery(QUERY_SEARCHBYNAME, {
        onCompleted(data) {
            const _users = data.searchUserByString;
            setTableData([])
            _users.map((_user, index) => {
                let user = {
                    key: index,
                    createdAt: _user.createdAt,
                    email: _user.email,
                    id: _user.id,
                    isActive: _user.isActive == true ? 'Active' : 'In-active',
                    registeredDate: _user.registeredDate,
                    username: _user.username,
                    name: _user.name,
                    roleName: _user.userRole.roleName,
                    roleValue: _user.userRole.roleName == constantRoles.ACE_HIGH_ADMIN ? rolesName.ACE_HIGH_ADMIN : _user.userRole.roleName == constantRoles.BPS_ADMIN ? rolesName.BPS_ADMIN : _user.userRole.roleName == constantRoles.SEARCHER ? rolesName.SEARCHER : _user.userRole.roleName == constantRoles.VIEWER ? rolesName.VIEWER : rolesName.superAdmin,
                    roleId: _user.userRole.id,
                    phone: _user.phone,

                }
                setTableData(oldArr => [...oldArr, user])
            })

        },
        onError(err) {
            console.log('onerror', err);
        },
        fetchPolicy: 'network-only',

    })

    const GET_USER_ROLES = gql`
    query Query {
        userRoles {
          isActive
          roleName
          userPermission {
            isActive
            routeUrl
            id
          }
          id
        }
      }`

    const RESEND_EMAIL = gql`
    mutation resendEnrollmentMailTOUser($email:String!) {
        resendEnrollmentMailTOUser(email: $email) {
          message
        }
      }`

    const UPDATE_ROLE = gql`
    mutation updateUserDetails($id:ID!,$username:String!,$name:String!,$email:String!,$userRole:String!,$isActive:Boolean!,$isDeleted:Boolean!){
        updateUserDetails(
          input: {
            id: $id
            username: $username
            name: $name
            email: $email
            userRole: $userRole
            isActive: $isActive
            isDeleted: $isDeleted
          }
        ) {
          message
        }
      }
    `

    const mapRoleName = (userRoles) => {
        userRoles.map((role) => {
            if (role.value === constantRoles.BPS_ADMIN) {
                return role.name = rolesName.BPS_ADMIN
            }
            if (role.value === constantRoles.ACE_HIGH_ADMIN) {
                return role.name = rolesName.ACE_HIGH_ADMIN
            }
        })
    }

    const columns = [

        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',

            width: '10%'
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: '10%',
            render: ((text, record) => {
                if (record.key === editable) {
                    return (
                        <>
                            <Input defaultValue={text} onChange={(e) => record.email = e.target.value} />
                        </>
                    )
                } else {
                    return (
                        <>
                            {text}
                        </>
                    )
                }
            })
        },
        {
            title: 'Created Date',
            dataIndex: 'createdAt',
            key: 'createdAt',

            width: '10%'
        },
        {
            title: "Registered Date",
            dataIndex: "registeredDate",
            key: "registeredDate",
            width: '10%'
        },
        {
            title: 'Role',
            dataIndex: 'roleValue',
            key: 'roleValue',
            width: '10%',
            render: ((text, record) => {
                if (record.key === editable) {
                    return (
                        <Select defaultValue={text} style={{ width: '100%' }} onChange={(value) => {
                            setRoleId(value)
                            record.roleName = value
                        }}>
                            {
                                rolesUser.map((role, index) => (
                                    <Option key={index} value={role.value}>{role.name}</Option>
                                ))
                            }
                        </Select>
                    )
                } else {
                    return (
                        <>
                            {text}
                        </>
                    )
                }
            })
        },
        {
            title: 'Status',
            dataIndex: 'isActive',
            key: 'isActive',
            width: '10%',
            render: ((text, record) => {
                if (editable === record.key) {
                    return (
                        <>
                            <Select defaultValue={text} style={{ width: '100%' }} onChange={(value) => { record.isActive = value }}>
                                <Option value={true}>Active</Option>
                                <Option value={false}>In-active</Option>
                            </Select>
                        </>
                    )
                } else {
                    return (
                        <>
                            {text}
                        </>
                    )
                }
            })
        },
        {
            title: 'Action',
            width: '10%',
            render: ((_, record) => {
                return (
                    <>
                        <Button type='link' hidden={record.key !== editable ? false : true} onClick={() => {
                            setEditable(record.key);
                            getUserRoles();
                        }}>
                            edit
                        </Button>

                        {(record.registeredDate == undefined || record.registeredDate == null) ?
                            (<Button type='link' hidden={record.key !== editable ? false : true} onClick={() => {
                                resendEmailHandler(record.email)
                            }}>
                                resend email
                            </Button>) : <></>
                        }

                        <Button type='link' hidden={record.key === editable ? false : true} onClick={() => {
                            updateHandler(record);
                            setEditable(null);
                        }}>
                            save
                        </Button>
                        <Button type='link' hidden={record.key === editable ? false : true}>
                            <Popconfirm title="Sure to cancel?" onConfirm={() => {
                                setEditable(null);
                                getUsesrs()
                            }}>
                                <a>Cancel</a>
                            </Popconfirm>
                        </Button>
                    </>
                )

            })
        },
    ];

    const [getUsesrs, { loading: getUsersLoading }] = useLazyQuery(GET_USERS, {
        onCompleted(data) {
            const _users = data.getAllUsers.users;
            setTotalPages({
                currentPage:data.getAllUsers.currentPage,
                limit:data.getAllUsers.limit,
                totalDocs:data.getAllUsers.totalDocs
            })
            setTableData([])
            _users.map((_user, index) => {
                let user = {
                    key: index,
                    username: _user.username,
                    name: _user.name,
                    email: _user.email,
                    roleName: _user.userRole.roleName,
                    roleValue: _user.userRole.roleName == constantRoles.ACE_HIGH_ADMIN ? rolesName.ACE_HIGH_ADMIN : _user.userRole.roleName == constantRoles.BPS_ADMIN ? rolesName.BPS_ADMIN : _user.userRole.roleName == constantRoles.SEARCHER ? rolesName.SEARCHER : _user.userRole.roleName == constantRoles.VIEWER ? rolesName.VIEWER : rolesName.superAdmin,
                    roleId: _user.userRole.id,
                    registeredDate: _user.registeredDate,
                    id: _user.id,
                    phone: _user.phone,
                    isActive: _user.isActive == true ? 'Active' : 'In-active',
                    createdAt: _user.createdAt,
                }
                setTableData(oldArr => [...oldArr, user])
            })

        },
        onError(err) {
            setAlertMessage(() => { return <Alert type="error" message={err.message}></Alert> })
            resetState()

        },
        fetchPolicy: 'network-only'
    })

    const [getUserRoles] = useLazyQuery(GET_USER_ROLES, {
        onCompleted(data) {
            let roles = data.userRoles;
            let currentRole = localStorage.getItem(Constants.userRole)
            let userRoles = roles.map((role) => {
                return (
                    {
                        value: role.roleName,
                        id: role.id
                    }
                )
            })
            for (const [index, role] of userRoles.entries()) {
                if (role.value === constantRoles.superAdmin) {
                    userRoles.splice(index, 1)
                }
            }
            mapRoleName(userRoles)
            setRoles(userRoles)
        },
        onError(err) {

            setAlertMessage(() => { return <Alert type="error" message={err.message}></Alert> })
            resetState()

        },
        fetchPolicy: 'network-only'
    })

    const [updateRole] = useMutation(UPDATE_ROLE);
    const [resendEmail] = useMutation(RESEND_EMAIL)

    useEffect(() => {
        getUsesrs()
    }, [])


    const updateHandler = async (record) => {
        try {
            const res = await updateRole({
                variables: {
                    id: record.id,
                    username: record.username,
                    name: record.name,
                    email: record.email,
                    phone: record.phone,
                    userRole: record.roleName,
                    isActive: record.isActive == "Active" || record.isActive == true ? true : false,
                    isDeleted: false
                }
            })

            if (res) {
                getUsesrs();

                setAlertMessage(() => { return <Alert type="success" message={`update Successfully !!`}></Alert> })
                resetState()

            }
        } catch (err) {
            getUsesrs();

            setAlertMessage(() => { return <Alert type="error" message={err.message}></Alert> })
            resetState()

        }
    }

    const resendEmailHandler = async (userEmail) => {
        try {
            const res = await resendEmail({
                variables: {
                    email: userEmail
                }
            })
            if (res) {

                setAlertMessage(() => { return <Alert type="success" message={`Email sent Successfully !!`}></Alert> })
                resetState()

            }
        } catch (err) {

            setAlertMessage(() => { return <Alert type="error" message={err.message}></Alert> })
            resetState()

        }
    }

    return (
        <>
            <section className='vh-fill'>
                <Navigation />

                <Container className='my-2 py-4'>
                    <Row>
                        <Col>
                            <h4>Users</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mb-2">
                            <Input.Search allowClear className='w-25' onSearch={(e) => {
                                getSearch({ variables: { searchString: e } })
                            }} />
                        </Col>
                    </Row>
                    <Row className="w-50">
                        <Col className="alertmessage mb-1 ">

                            {alertMessage}
                        </Col>

                    </Row>
                    <Row>
                        {
                            getSearchLoading || getUsersLoading ? <Spin className='mt-5 d-flex justify-content-center' size='large'></Spin> : <DataTable showCheck={false} totalPages={totalPages} currentPage={(cp) => { getUsesrs({ variables: { sortCreatedAt: -1, page: cp } }) }} columnData={columns} tableData={tableData} scrollX={1800} scrollY={500} />
                        }
                    </Row>
                </Container>
                <Footer />
            </section>
        </>
    )
}


export default UserTable