import React, { useEffect, useState } from "react";
import { Form, Image, Input, message,Alert } from 'antd';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useLocation ,  useNavigate } from "react-router-dom";
//import { toast, Toaster } from 'react-hot-toast';

import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { PasswordInput } from 'antd-password-input-strength';
import { gql, useMutation } from "@apollo/client";
import {patterns} from '../../src/Constants';

const ResetPassword = () => {

    const query = new URLSearchParams(useLocation().search);
    const location = useLocation();
    const locationName = location.pathname.split('/');
    const navigate=useNavigate();
    const [level, setLevel] = useState(0)
    const [token, setToken] = useState()
    const minLevel = 0;
    const errorMessage = 'Password is too weak';
    const passworderror=<div>Password should Contain atleast 8 character,a special character & an uppercase letter</div>
    const checkPassStrength = async () => { 
            return level >= minLevel ? Promise.resolve() : Promise.reject(errorMessage);     
    }
    
    const [alertMessage, setAlertMessage] = useState()

    const resetState = () => {
        setTimeout(() => {
            setAlertMessage()
        }, 3000)
    }

    const resetPass = gql`
    mutation ForgotPwdReset($password:String!,$token:String!) {
        forgotPwdReset(input: {
            password : $password,
            token : $token
        }) {
          message
        }
      }
    `
    useEffect(() => {
        let urlArr = window.location.pathname.split('/');
        setToken(urlArr[urlArr.length - 1])
    }, [])

    const [updatePass] = useMutation(resetPass);

    const handleFormSubmit = async (val) => {
        const newPass = val.newPass;
        const confirmPass = val.confirmPass;
        if (newPass == confirmPass) {
            let password = {
                id: token,
                password: newPass
            }
            const res = await updatePass({
                variables: {
                    password: password.password,
                    token : password.id
                }
            }).then((mes) => {
                setAlertMessage(() => { return <Alert type="success" message={`Password Updated Successfully !!`}></Alert> })
                resetState()    
                navigate('/login');
            }).catch((err) => {
                setAlertMessage(() => { return <Alert type="error" message={`Something went wrong `}></Alert>})
                resetState()
    
            })

        } else {
          
            setAlertMessage(() => { return <Alert type="error" message={`Password didn’t match! Check again.`}></Alert>})
            resetState()

        }
    }
    return (
        <>
            <section>
                <Container className='py-2'>
                    <a href="/"><img src="/images/Dahon_Cropped_260x.jpg" className="nav-logo" /></a>
                </Container>
                <Container className='header mb-5'>
                    <Row className='p-4 justify-content-center'>
                        <Col md={4} className='header-box'>
                            <Row className='bg-light p-4 border-bottom text-center'>
                                <Col>
                                    <h2>{locationName[1] === 'create-password'? "Create" : "Reset"} Password</h2>
                                </Col>
                            </Row>
                            <Row className='p-4'>
                                <Col>
                                    <Col>
                                        <Form layout="vertical" name="dynamic_rule" onFinish={(values) => handleFormSubmit(values)}>
                                            <Row className="justify-content-center">
                                                <Col lg={10}>
                                                    <Form.Item label="Password" name="newPass" rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input your password',
                                                        },
                                                        {
                                                            validator: checkPassStrength,
                                                            message: errorMessage
                                                        },
                                                        {
                                                            pattern:patterns.passwordCheck,
                                                            message: passworderror,
                                                        }
                                                    ]}>
                                                        <PasswordInput placeholder="Please input your password" iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} onLevelChange={setLevel} />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="confirmPass"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please re-enter your password',
                                                            },

                                                        ]}
                                                        label="Confirm Password">
                                                        <PasswordInput placeholder="Please re-enter your password" iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row className="justify-content-center text-center alertmessage">
                                            <Col lg={10}>

                                                        {alertMessage}
                                            </Col>
                                            </Row>

                                            <Row className="justify-content-center text-center pt-4">
                                                <Col lg={12}>
                                                    <Form.Item>
                                                        <Button type="submit" className="bps-primary-button">{locationName[1] === 'create-password'? "Create" : "Reset"}</Button>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col>

                        </Col>
                    </Row>
                </Container>
            </section>

        </>
    )
}


export default ResetPassword;


