import React, { Fragment } from 'react';
import { Nav, Navbar, NavDropdown, Container, Image, Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Constants, { roles } from '../Constants';
import './Navigation.css';
import { useNavigate } from 'react-router-dom';
import { googleLogout } from '@react-oauth/google';



const Navigation = () => {

  const userRole = localStorage.getItem(Constants.userRole);
  const redirect = useNavigate();
  const logOut = () => {
    googleLogout();
    localStorage.clear();
    redirect('/login');
  }

  const activeLink = ({ isActive }) => {
    if (isActive) {
      return 'active'
    } else {
      return 'navLink'
    }
  }

  return (
    <>
      <Navbar expand="xxl" bg="white">
        <Container className='border-bottom'>
          <Navbar.Brand href="/" className='ms-0 nav-logo'><Image fluid src='images/Dahon_Cropped_260x.jpg' className='nav-logo' /></Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mx-0">
              <Nav.Link className="border-bottom-0 px-0"><NavLink className={activeLink} to="/searchrebate">Search Rebate</NavLink></Nav.Link>
              <NavDropdown title="Upload" className='mt-0 px-xl-0 ps-4'  hidden={userRole !== roles.superAdmin ? true : false}>
                <NavDropdown.Item><NavLink className={activeLink} to="/upload">Serial Number Upload</NavLink></NavDropdown.Item>
                <NavDropdown.Item><NavLink className={activeLink} to="/model-number-uploader">Bike Model Upload</NavLink></NavDropdown.Item>
              </NavDropdown>
              <Nav.Link className="border-bottom-0 px-0"><NavLink className={activeLink} to="/reports">Reports</NavLink></Nav.Link>
              <Nav.Link className="border-bottom-0 px-0"><NavLink className={activeLink} to="/rebateApproval">Rebate Approval</NavLink></Nav.Link>
              <NavDropdown title="Users" className='mt-0 px-xl-0 ps-4' hidden={userRole !== roles.superAdmin ? true : false}>
                <NavDropdown.Item><NavLink className={activeLink} to="/users">Users</NavLink></NavDropdown.Item>
                <NavDropdown.Item><NavLink className={activeLink} to="/add-user">Add User</NavLink></NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="BPS Admin" className={`mt-0 px-xl-0 ps-4`} hidden={userRole !== roles.BPS_ADMIN && userRole !== roles.superAdmin ? true : false}>
                <NavDropdown.Item hidden={userRole !== roles.superAdmin ? true : false}><NavLink className={activeLink} to="/invoiceOrder">Invoice Order</NavLink></NavDropdown.Item>
                <NavDropdown.Item><NavLink className={activeLink} to="/editRebate">Edit Rebate</NavLink></NavDropdown.Item>
                <NavDropdown.Item><NavLink className={activeLink} to="/generateReport">Download Payment Process</NavLink></NavDropdown.Item>
              </NavDropdown>
              <Nav.Link>
                <p onClick={logOut} className=' w-100 text-info'>
                  Logout
                </p>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Navigation;