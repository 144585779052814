import { useContext } from "react";
import { Navigate, Outlet ,useLocation } from "react-router-dom";
import Constants from "../Constants";
import AuthContext from "../Context/AuthProvider";



const useAuth = (val) => {
    localStorage.setItem(Constants.loggedIn, val.isloggedIn)
    let logIn = localStorage.getItem(Constants.loggedIn)=== 'true'? true : false
    const user = { isloggedIn: logIn };
    return user;
};

const ProtectedRoutes = () => {
    const location = useLocation();
    const { auth } = useContext(AuthContext)
    const isAuth = useAuth(auth);
    return isAuth.isloggedIn ? <Outlet /> : <Navigate to={'/login'}  state={{from : location}} replace/>;
}

export default ProtectedRoutes;


export  const AuthorizedUsers = (props) => {
    const { auth } = useContext(AuthContext)
    const userRole =  localStorage.getItem(Constants.userRole);
    return props.role.find((rol)=>true ? userRole === rol : false) ? <Outlet /> : <Navigate to={'/searchrebate'}  replace/>;
}
