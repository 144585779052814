import React from 'react';
import Uploader from './Uploader';
import {uploadFileMutation, fileSubscription} from '../Common/queries';



const SerialNumberUploader = ()=>{
    return (
        <>
            <Uploader  mutation={uploadFileMutation} subscription={fileSubscription} subData={'serialNumberFileUpdateStatus'}/>
        </>
    )
}


export default SerialNumberUploader;