import { Popover, Typography } from "antd"
import './Content.css';

const DownloadPopover = ({ urls, label = "Download", title }) => {
    return (
        <Popover content={<Content urls={urls} title={title} />}>
            <span className="custom-link">{label}</span>
        </Popover>
    )
}

const Content = ({ urls, title = "Click to Download" }) => {
    return (
        <div style={{
            width: "250px",
        }}>
            <div className="popover-title">{title}</div>
            {
                urls.map((url) => (
                    <a href={url} download rel="noreferrer" className="custom-link" style={{ display: 'block' }}>
                        Download
                    </a>
                ))
            }
        </div >
    )
}

export default DownloadPopover