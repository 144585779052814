import React, { useEffect, useState } from "react";
import { Container, Col, Row, Button } from "react-bootstrap";
import {
  Input,
  Modal,
  Form,
  Select,
  Radio,
  message,
  Spin,
  Upload,
  Button as AntButton,
} from "antd";
import Navigation from "./Navigation";
import Header from "./Header";
import Footer from "./Footer";
import "./Content.css";
import DataTable from "../Common/DataTable";
import { gql, useLazyQuery, useQuery, useMutation } from "@apollo/client";
import DownloadPopover from './DownloadPopOver';
import Constants, {
  paymentType,
  rewardTypes,
  displayRewardTypeName,
} from "../Constants";
const { TextArea } = Input;
const { Dragger } = Upload;

const SearchPayment = () => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [search, setSearch] = useState();
  const [tableData, setTableData] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [totalPages, setTotalPages] = useState({});
  const [notesTotalPages, setNotesTotalPages] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [createNote, setCreateNote] = useState(false);
  const [notesTable, setNotesTable] = useState();
  const [selectedModalId, setSelectedModalId] = useState();
  const [files, setfileList] = useState();
  const [noteVal, setNoteVal] = useState();

  const INDIVIDUAL_PAYMENT_LIST = gql`
    query GetAllIndividualPayment($page: Int) {
      getAllIndividualPayment(page: $page) {
        individualPayments {
          bikeModel
          bikeSerialNumber
          customerFirstName
          customerLastName
          customerAddress1
          customerAddress2
          customerCity
          customerState
          customerZip
          customerEmail
          customerPhoneNumber
          rewardType
          trackingNumber
          status
          reason
          paymentAmount
          approvedAtTime
          createdAt
          updatedAt
          redeemedAt
          shippedDate
          redeemed
          submittedBy
          isOrderAssigned
          receiptDownloadUrl
          emailSentDate
          dateOfSale
          approvedByUser {
            name
          }
        }
        totalPages
        totalDocs
        currentPage
        limit
      }
    }
  `;

  const DeleteNotesById = gql`
    mutation DeleteNoteById($deleteNoteByIdId: ID!) {
      deleteNoteById(id: $deleteNoteByIdId)
    }
  `;

  const getAllNotes = gql`
    query GetNotesById($individualPaymentId: String!, $page: Int) {
      getNotesById(individualPaymentId: $individualPaymentId, page: $page) {
        notes {
          signedUrl
          filename
          individualPayment
          _id
          noteCreator {
            name
            _id
          }
          note
          createdAt
          canDelete
        }
        totalPages
        totalDocs
        limit
        currentPage
      }
    }
  `;

  const CreateNotes = gql`
    mutation createNote(
      $individualPayment: String!
      $note: String!
      $file: Upload
    ) {
      createNote(
        individualPayment: $individualPayment
        note: $note
        file: $file
      ) {
        id
      }
    }
  `;

  const getString = (array) => {
    if (array.length === 0) {
      return "";
    } else {
      let outputString = "";
      array.map((item, index) => {
        if (index !== array.length - 1) {
          outputString = outputString + item + ", ";
        } else {
          outputString = outputString + item;
        }
      });
      return outputString;
    }
  };

  const [createnotes] = useMutation(CreateNotes);

  const [deletenotes] = useMutation(DeleteNotesById);

  const [getNotes, { loading: allNotesLoading }] = useLazyQuery(getAllNotes, {
    onCompleted(data) {
      setNotesTotalPages({
        currentPage: data.getNotesById.currentPage,
        limit: data.getNotesById.limit,
        totalDocs: data.getNotesById.totalDocs,
      });
      const notes = data.getNotesById.notes;
      let notesDataArr = [];
      let table_data = {};
      notes.map((record) => {
        table_data = {
          notesCreator: record.noteCreator.name,
          note: record.note,
          noteImage: record.signedUrl,
          id: record._id,
          creationDate: record.createdAt,
          Action: record.canDelete,
        };
        notesDataArr.push(table_data);
      });
      setNotesTable(notesDataArr);
    },
    fetchPolicy: "network-only",
  });

  const [paymentArr, { loading: paymentArrLoading }] = useLazyQuery(
    INDIVIDUAL_PAYMENT_LIST,
    {
      onCompleted(data) {
        const _IndividualPayArr =
          data.getAllIndividualPayment.individualPayments;
        setTableData([]);
        setTotalPages({
          currentPage: data.getAllIndividualPayment.currentPage,
          limit: data.getAllIndividualPayment.limit,
          totalDocs: data.getAllIndividualPayment.totalDocs,
        });
        _IndividualPayArr.map((_IndividualPay, index) => {
          let createIndividualPayRow = {
            key: index,
            declineReason: _IndividualPay.reason,
            customerFirstName: _IndividualPay.customerFirstName,
            customerLastName: _IndividualPay.customerLastName,
            customerEmail: _IndividualPay.customerEmail,
            status: _IndividualPay.status,
            mailingAddress1: _IndividualPay.customerAddress1,
            mailingAddress2: _IndividualPay.customerAddress2,
            mailingCity: _IndividualPay.customerCity,
            mailingState: _IndividualPay.customerState,
            mailingZip: _IndividualPay.customerZip,
            phone: _IndividualPay.customerPhoneNumber,
            paymentAmount: `$ ${_IndividualPay.paymentAmount}`,
            createdAt: _IndividualPay.createdAt,
            trackingNumber: _IndividualPay.trackingNumber,
            bikeModel: _IndividualPay.bikeModel,
            bikeSerialNumber: _IndividualPay.bikeSerialNumber,
            approvedAtTime: _IndividualPay.approvedAtTime,
            rewardType: _IndividualPay.rewardType,
            approvedByUser: _IndividualPay.approvedByUser?.name,
            redeemedAt: _IndividualPay.redeemedAt,
            receiptDownloadUrl: _IndividualPay.receiptDownloadUrl,
            emailSentDate: _IndividualPay.emailSentDate,
            shippedDate: _IndividualPay.shippedDate,
            dateOfSale: _IndividualPay.dateOfSale,
          };
          setTableData((oldArr) => [...oldArr, createIndividualPayRow]);
        });
      },
      onError(err) {
        console.log(err);
      },
      fetchPolicy: "network-only",
    }
  );

  const QUERY_PAYMENT = gql`
    query GetIndividualPaymentBySearch($searchString: String!, $type: String) {
      getIndividualPaymentBySearch(searchString: $searchString, type: $type) {
        bikeModel
        bikeSerialNumber
        customerFirstName
        customerLastName
        customerAddress1
        customerAddress2
        customerCity
        customerState
        customerZip
        customerEmail
        customerPhoneNumber
        rewardType
        trackingNumber
        status
        receiptDownloadUrl
        reason
        approvedAtTime
        createdAt
        emailSentDate
        updatedAt
        redeemedAt
        shippedDate
        redeemed
        submittedBy
        dateOfSale
        isOrderAssigned
        paymentAmount
        approvedByUser {
          name
        }
      }
    }
  `;

  useEffect(() => {
    paymentArr();
  }, []);

  const [getSearch, { loading: getSearchLoading, error, data }] = useLazyQuery(
    QUERY_PAYMENT,
    {
      onCompleted(data) {
        const _IndividualPayArr = data.getIndividualPaymentBySearch;
        setTableData([]);
        _IndividualPayArr.map((_IndividualPay, index) => {
          let createIndividualPayRow = {
            key: index,
            declineReason: _IndividualPay.reason,
            customerFirstName: _IndividualPay.customerFirstName,
            customerLastName: _IndividualPay.customerLastName,
            customerEmail: _IndividualPay.customerEmail,
            status: _IndividualPay.status,
            mailingAddress1: _IndividualPay.customerAddress1,
            mailingAddress2: _IndividualPay.customerAddress2,
            mailingCity: _IndividualPay.customerCity,
            mailingState: _IndividualPay.customerState,
            mailingZip: _IndividualPay.customerZip,
            phone: _IndividualPay.customerPhoneNumber,
            paymentAmount: `$ ${_IndividualPay.paymentAmount}`,
            createdAt: _IndividualPay.createdAt,
            trackingNumber: _IndividualPay.trackingNumber,
            bikeModel: _IndividualPay.bikeModel,
            bikeSerialNumber: _IndividualPay.bikeSerialNumber,
            approvedAtTime: _IndividualPay.approvedAtTime,
            rewardType: _IndividualPay.rewardType,
            approvedByUser: _IndividualPay.approvedByUser?.name,
            redeemedAt: _IndividualPay.redeemedAt,
            receiptDownloadUrl: _IndividualPay.receiptDownloadUrl,
            emailSentDate: _IndividualPay.emailSentDate,
            shippedDate: _IndividualPay.shippedDate,
            dateOfSale: _IndividualPay.dateOfSale,
          };
          setTableData((oldArr) => [...oldArr, createIndividualPayRow]);
        });
      },
      onError(err) {
        console.log("onerror", err);
      },
      fetchPolicy: "network-only",
    }
  );

  const handleOk = () => {
    setShowModal(false);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const modalwindow = () => {
    const handlenotes = async ({ file, onSuccess }) => {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    };

    const props = {
      name: "file",
      maxCount: 1,
      customRequest: handlenotes,
      onChange({ file, fileList, event }) {
        setfileList(file.originFileObj);
      },
    };

    const columns = [
      {
        title: "Notes Creator",
        dataIndex: "notesCreator",
        key: "notesCreator",
        width: "10%",
      },
      {
        title: "Note",
        dataIndex: "note",
        width: "30%",

        key: "note",
      },
      {
        title: "Note Image",
        dataIndex: "noteImage",
        key: "notesImage",
        width: "10%",
        render: (_, record) => {
          return (
            <>
              <a href={`${record.notesImage}`} download>
                Download
              </a>
            </>
          );
        },
      },
      {
        title: "Creation Date",
        dataIndex: "creationDate",
        key: "creationDate",
        width: "10%",
      },
      {
        title: "Action",
        key: "Action",
        width: "10%",
        render: (_, record) => {
          if (record.Action) {
            return (
              <AntButton
                type="link"
                onClick={() => {
                  handleDeleteNote(record.id);
                }}
              >
                Delete
              </AntButton>
            );
          } else {
            return "";
          }
        },
      },
    ];

    const handleFormSubmit = async (values) => {
      const { NoteText } = form.getFieldsValue();
      const id = await createnotes({
        variables: {
          individualPayment: selectedModalId,
          note: NoteText,
          file: files,
        },
      });
      if (id != "undefined") {
        getNotes({
          variables: {
            individualPaymentId: selectedModalId,
          },
        });
      }
      form.resetFields();
    };

    const handleDeleteNote = async (id) => {
      const isdeleted = await deletenotes({
        variables: {
          deleteNoteByIdId: id,
        },
      });
      if (isdeleted.data.deleteNoteById === true) {
        await getNotes({
          variables: {
            individualPaymentId: selectedModalId,
          },
          fetchPolicy: "network-only",
        });
      }
    };

    return (
      <div>
        <Modal
          title="Notes"
          visible={showModal}
          onOk={handleOk}
          onCancel={handleCancel}
          width={"70vw"}
        >
          <Form
            form={form}
            layout="vertical"
            name="dynamic_rule"
            onFinish={(values) => handleFormSubmit(values)}
          >
            <Row className="mb-1">
              <Col>
                <Row>
                  <Form.Item name={"NoteText"}>
                    <TextArea
                      className="w-75 m-2"
                      value={noteVal}
                      style={{ height: 100 }}
                    ></TextArea>
                  </Form.Item>
                </Row>
                <Row>
                  <div>
                    <Form.Item name={"submit"}>
                      <Button
                        type="submit"
                        className="powur-btn-secondary btn-sm me-0 ms-2"
                      >
                        Create Note
                      </Button>
                    </Form.Item>
                  </div>
                </Row>
              </Col>
              <Col>
                <div>
                  <Dragger className="p-3 m-1" {...props}>
                    <p className="ant-upload-text">
                      Click or drag file to this area to upload
                    </p>
                  </Dragger>
                </div>
              </Col>
            </Row>
            <Row>
              {allNotesLoading ? (
                <Spin
                  className="mt-5 d-flex justify-content-center"
                  size="large"
                ></Spin>
              ) : (
                <DataTable
                  columnData={columns}
                  tableData={notesTable}
                  scrollX={1300}
                  totalPages={notesTotalPages}
                  currentPage={(cp) => {
                    getNotes({
                      variables: {
                        individualPaymentId: selectedModalId,
                        page: cp,
                      },
                    });
                  }}
                  scrollY={200}
                ></DataTable>
              )}
            </Row>
          </Form>
        </Modal>
      </div>
    );
  };

  const prepareModal = (id) => {
    getNotes({
      variables: {
        individualPaymentId: id,
        page: 1,
      },
    });
    setSelectedModalId(id);
    setShowModal(true);
    modalwindow();
  };

  const columns = [
    {
      title: "First Name",
      dataIndex: "customerFirstName",
      key: "firstName",
      fixed: "left",
      width:"7%",
      render: (_, record) => {
        return (
          <>
            {record.customerFirstName}
            {/* <img
              src="/images/clipboard.svg"
              className="ms-1"
              alt=""
              onClick={() => {
                prepareModal(record.id);
              }}
            ></img> */}
          </>
        );
      },
    },
    {
      title: "Last Name",
      dataIndex: "customerLastName",
      key: "lastName",
      fixed: "left",
      width:"7%",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width:"5%",
    },
    {
      title: "Amount",
      dataIndex: "paymentAmount",
      key: "amount",
      width:"5%",
    },
    {
      title: "Bike Model",
      dataIndex: "bikeModel",
      key: "bikeModel",
      width:"5%",
    },
    {
      title: "Bike Serial Number",
      dataIndex: "bikeSerialNumber",
      key: "bikeSerialNumber",
      width:"5%",
    },
    {
      title: "Date of sale",
      dataIndex: "dateOfSale",
      key: "dateOfSale",
      width:"5%",
    },
    {
      title: "Download Receipt",
      dataIndex: "receiptDownloadUrl",
      key: "receiptDownloadUrl",
      width: "5%",
      render: (_, { receiptDownloadUrl }) =>
        receiptDownloadUrl.length > 0 && (
          <DownloadPopover urls={receiptDownloadUrl} title="Download Receipt" />
        ),
    },
    {
      title: "Tracking Number",
      dataIndex: "trackingNumber",
      key: "trackingNumber",
      width:"5%",
    },
    {
      title: "Email",
      dataIndex: "customerEmail",
      key: "email",
      width:"8%",
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
      width:"5%",
    },
    {
      title: "Date Submitted",
      dataIndex: "createdAt",
      key: "createdAt",
      width:"5%",
    },
    {
      title: "Approved/Declined Date",
      dataIndex: "approvedAtTime",
      key: "approvedAtTime",
      width:"6%",
    },
    {
      title: "Approved/Declined By",
      dataIndex: "approvedByUser",
      key: "approvedByUser",
      width:"6%",
    },
    {
      title: "Email Sent Date",
      dataIndex: "emailSentDate",
      key: "emailSentDate",
      width:"5%",
    },
    {
      title: "Shipped Date",
      dataIndex: "shippedDate",
      key: "shippedDate",
      width:"5%",
    },
    {
      title: "Reward Type",
      dataIndex: "rewardType",
      key: "rewardType",
      width:"8%",
      render: (_, record) => {
        if (record.rewardType != null || record.rewardType !== "") {
          if (record.rewardType === rewardTypes.physical) {
            return `${displayRewardTypeName.PHYSICAL_ALIAS}`;
          } else if (record.rewardType === rewardTypes.virtual) {
            return `${displayRewardTypeName.VIRTUAL_ALIAS}`;
          } else if (record.rewardType === rewardTypes.check) {
            return `${displayRewardTypeName.CHECK_ALIAS}`;
          }
        }
      },
    },
    {
      title: " Customer Address1",
      dataIndex: "mailingAddress1",
      key: "mailingAddress1",
      width:"8%",
    },
    {
      title: " Customer Address2",
      dataIndex: "mailingAddress2",
      key: "mailingAddress2",
      width:"7%",
    },
    {
      title: "Customer City",
      dataIndex: "mailingCity",
      key: "mailingCity",
      width:"5%",
    },
    {
      title: "Customer State",
      dataIndex: "mailingState",
      key: "mailingState",
      width:"5%",
    },
    {
      title: "Customer Zip",
      dataIndex: "mailingZip",
      key: "mailingZip",
      width:"5%",
    },
    

  ];

  return (
    <>
      {showModal ? modalwindow() : null}
      <section className="vh-fill">
        <Navigation />
        <Container className="my-2 py-4">
          <Row>
            <Col>
              <h4>Search</h4>
            </Col>
          </Row>

          <Row>
            <Col className="mb-2">
              <Input.Search
                allowClear
                className="w-25"
                onSearch={(e) => {
                  let type = "none";

                  if (
                    new RegExp(
                      /\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])*/
                    ).test(e)
                  ) {
                    type = "date";
                  }
                  getSearch({ variables: { searchString: e, type: type } });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              {paymentArrLoading || getSearchLoading ? (
                <Spin
                  className="mt-5 d-flex justify-content-center"
                  size="large"
                ></Spin>
              ) : (
                <DataTable
                  totalPages={totalPages}
                  currentPage={(cp) => {
                    paymentArr({ variables: { page: cp } });
                  }}
                  showCheck={false}
                  columnData={columns}
                  tableData={tableData}
                  scrollX={4000}
                  scrollY={500}
                />
              )}
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
};

export default SearchPayment;
