import React from 'react';
import './Content.css';
import { gql} from '@apollo/client'
import Fund from './fund';

const FundOrder = () => {

    const GET_APPROVED_PAYMENTS = gql`
    query {
            getAllOrder {
              orderId
              funded
              fundedAt
              status
              id
              createdAt
              s3SignedFileUrl
              reportTotal,
            }
        }`
  
    const FUND = gql`
          mutation fundOrder($id : ID!) {
            fundOrder(id:$id) {
            message
             }
        }`

    return (
        <>
            <Fund GET_APPROVED_PAYMENTS={GET_APPROVED_PAYMENTS} FUND={FUND} />
        </>
    );
}

export default FundOrder;