import { gql, useMutation } from "@apollo/client";
import {
    Form,
    Col, Row, DatePicker, Button, Select, Alert
} from 'antd';
import { useState } from 'react'
import { Container } from 'react-bootstrap'
import Footer from "./Footer";
import Navigation from './Navigation';



const SEND_REPORT = gql`
  mutation GeneratePaymentProcessReport($startDate: String, $endDate: String) {
  generatePaymentProcessReport(startDate: $startDate, endDate: $endDate) {
    url
  }
}
`;
const GenerateReport = (prop) => {
	const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
	const [alertMessage, setAlertMessage] = useState()
    const [form]=Form.useForm();
	
	const resetState = () => {
		setTimeout(() => {
			setAlertMessage(<></>)
		}, 3000)
	}
    const [sendReport] = useMutation(SEND_REPORT)

	  const onFinish = async (values) => {
        try {
            const res = await sendReport({
                variables: {
                    startDate: startDate,
                    endDate: endDate,
                   
                }
            })
            if (res) {
                setAlertMessage(() => {
                    window.scrollTo({ top: 0, left: 0, behaviour: "smooth" });
                    return <Alert type="success" message={`Report Generated Successfully !!`}></Alert>
                })
                resetState();

                setTimeout(() => {
                    window.location.replace(res.data.generatePaymentProcessReport.url);
                }, 2000)
                setTimeout(() => {
                    setAlertMessage(() => {
                        window.scrollTo({ top: 0, left: 0, behaviour: "smooth" });
                        return <Alert type="success" message={`Downloaded Successfully !!`}></Alert>
                    })
                    resetState();
                }, 6000)
            }
        } catch (err) {
            setAlertMessage(() => {
                window.scrollTo({ top: 0, left: 0, behaviour: "smooth" });
                return <Alert type="warning" message={err.message}></Alert>
            })
            resetState()

        }



    };
     const startDateCapture = date => {
        if(date != null){
            setStartDate(date.format('YYYY-MM-DD'))
        }
        else{
            setStartDate();
            setEndDate();
            form.resetFields(["startDate","endDate"]);
        }
    }

    const endDateCapture = date => {
        if(date != null){
            setEndDate(date.format('YYYY-MM-DD'))
        }
        else{
            setEndDate();
            setStartDate();
            form.resetFields(["startDate","endDate"]);
        }
    }

	return (
        <>
      <section className='vh-fill'>
        <Navigation />
         <div className="mt-5">
		<Container>
			<h2>Payment Process Report</h2>

			<div className="row mb-3">
				<div className="col">{alertMessage}</div>
			</div>

			<Form
				name="report"
				onFinish={onFinish}
				layout="vertical"
				requiredMark={false}
			>
				 <div className="container bg-light p-2 mt-4">
                                        <div className="row">
                                            <div className="col-6 d-flex justify-content-end">
                                                <div className="form-group">
                                                    <Form.Item label="Start Date" value="sdate" name="startDate">
                                                        <DatePicker onChange={startDateCapture} />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-group">
                                                    <Form.Item label="End Date" value="edate" name="endDate">
                                                        <DatePicker onChange={endDateCapture} disabledDate={d => d.isBefore(startDate)} />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                          <Row>
                                        <Col span={6} offset={10}>
                                            <Button type="primary" htmlType="submit" shape="round" size="large">
                                                Export Report
                                            </Button>
                                        </Col>
                                    </Row>
				
			</Form>
		</Container>
        </div>
         </section>
         <Footer />
            </>
	)
}

export default GenerateReport
