import { InboxOutlined } from '@ant-design/icons';
import React, { useState, useCallback } from 'react';
import Navigation from './Navigation';
import { Upload, Alert } from 'antd';
import { useMutation, useSubscription } from "@apollo/client";
import ReactHtmlParser from 'react-html-parser'; 
import Footer from './Footer';

const { Dragger } = Upload;

const Uploader = (properties) => {

    const [showInitialMessage, setshowInitialMessage] = useState(false);
    const [showValidationMessage, setshowValidationMessage] = useState(false);
    const [showInfoMessage, setshowInfoMessage] = useState(false);
    const [showSuccessMessage, setshowSuccessMessage] = useState(false);
    const [validationMessage, setvalidationMessage] = useState('');
    const [infoMesage, setinfoMesage] = useState('');
    const [successMessage, setsuccessMessage] = useState('');

    const [uploadFile] = useMutation(properties.mutation);
    const onDrop = useCallback(
        (file) => {
            try {
                uploadFile({ variables: { file : file } });
                setshowInitialMessage(true);
            } catch (err) {
                console.log('in uploader err: ', err);
            }
        },
        []
    );

    const props = {
        name: 'file',
        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        multiple: true,
        maxCount: 1,
        beforeUpload: () => {return false},
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                onDrop(info.file)
            }
        },
    };


    const { data, loading } = useSubscription(properties.subscription, {
        variables: {},
        onSubscriptionData: ({ subscriptionData: { data } }) => {
            if (data) {
                let errorMessages = data[properties.subData]['message'];
               
                let validationMessages = ""
                let infoMessages = ""
                let successMessages = ""
                setshowInitialMessage(false);
                setshowSuccessMessage(false);
                setshowInfoMessage(false);
                setshowValidationMessage(false);
                if (data[properties.subData]['type'] === 'error') {
                    setshowInfoMessage(false);
                    setshowValidationMessage(true);
                    validationMessages = errorMessages;
                    setvalidationMessage(validationMessages);
                }
                else if (data[properties.subData]['type'] === 'info') {
                    setshowValidationMessage(false);
                    setshowInfoMessage(true);
                    infoMessages = errorMessages;
                    setinfoMesage(infoMessages);
                }
                else if (data[properties.subData]['type'] === 'success') {
                    setshowInfoMessage(false);
                    setshowSuccessMessage(true);
                    successMessages = errorMessages;
                    setsuccessMessage(successMessages);
                }
            }
        }
    });
    return (
        <>
            <section className='vh-fill'>
                <Navigation />
                <div className='container'>
                    <div className="row">
                        <div className="col-12 mt-5">
                            <Dragger {...props}>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            </Dragger>
                        </div>
                    </div>
                    <div className='row mt-5'>
                        <div className='col-6'>
                            <Alert  type="secondary" message="  Please wait till the file validaton is complete." style={{ display: ((showInitialMessage && !showValidationMessage && !showInfoMessage && !showSuccessMessage) ? 'flex' : 'none') }}/>
                            <Alert  type="error" message={ReactHtmlParser (validationMessage)} style={{ display: ((showValidationMessage && !showInfoMessage && !showSuccessMessage && !showInitialMessage) ? 'flex' : 'none') }}/>
                            <Alert  type='info' message={infoMesage} style={{ display: ((showInfoMessage && !showSuccessMessage && !showInitialMessage && !showValidationMessage) ? 'flex' : 'none') }}/>
                            <Alert  type="success" message={successMessage} style={{ display: ((showSuccessMessage && !showInfoMessage && !showInitialMessage && !showValidationMessage) ? 'flex' : 'none') }}/>
                        </div>
                    </div>
                </div>
              
            </section>
            <Footer />
        </>
    )
}

export default Uploader;