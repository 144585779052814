import React, { useContext,useEffect,useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { Container, Col, Row, Button, Image } from 'react-bootstrap';
import './Content.css';
import './Navigation.css';
import Constants, { roles } from '../Constants';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Input,Alert,Spin } from 'antd';
import AuthContext from '../Context/AuthProvider';
import { Form } from 'antd';
import { gql, useMutation } from '@apollo/client'

const GET_DETAILS_FROM_TOKEN = gql`
  mutation GetUserDetailsViaToken($token: String!) {
    getUserDetailsViaToken(token: $token) {
      username
      password
      name
      email
      encryptedEmail
      phone
      userImage
      googleAuthToken
      isDeleted
      isActive
      jwtToken
      id
      createdAt
      userRole {
        isActive
        roleName
        id
        userPermission {
          isActive
          routeUrl
          id
        }
      }
      roles {
        isActive
        roleName
        id
        userPermission {
          isActive
          routeUrl
          id
        }
      }
    }
  }
`;

const LogIn = () => {

    const redirect = useNavigate();
    localStorage.setItem(Constants.loggedIn, false);
    const [form] = Form.useForm();
    const { setAuth } = useContext(AuthContext)

    const [alertMessage, setAlertMessage] = useState()

    const resetState = () => {
         setTimeout(() => {
             setAlertMessage()
         }, 3000)
     }

    const USER_DATA = gql`
    mutation login($email:String!, $password:String!) {
        login(input: { email: $email, password: $password }) {
          username
          name
          email
          encryptedEmail
          userImage
          googleAuthToken
          isDeleted
          isActive
          jwtToken
          id
          userRole{
            roleName
          }
          roles {
            isActive
            roleName
            id
          }
        }
      }`

    const [userData, { loading, error }] = useMutation(USER_DATA);
    const [getUserDataFromToken] = useMutation(GET_DETAILS_FROM_TOKEN)

    // const login = useGoogleLogin({
    //     onSuccess: async tokenResponse => {
    //         const userInfo = await fetch(process.env.REACT_APP_GOOGLE_API, {
    //             headers: new Headers({
    //                 'Authorization': `${tokenResponse.token_type} ${tokenResponse.access_token}`
    //             }),
    //         }).catch((err) => {
    //             setAlertMessage(() => { return <Alert type="warning" message={`Login Failed`}></Alert> })
    //             resetState()    
    //         })
    //         const res = await userInfo.json();
    //         if (res) {
    //             let userData = {
    //                 email: res.email,
    //                 password: ''
    //             }
    //             setAuth({ isloggedIn: true })
    //             handleFormSubmit(userData)
    //         }
    //     },
    //     onError: async err => {
    //         setAlertMessage(() => { return <Alert type="warning" message={err.message}></Alert> })
    //         resetState()
    //     }
    // });

   //get Token
   const [searchParams] = useSearchParams();
   const token = searchParams.get("token");

   // Common function to store response data
    function setData(data) {
      const currentRole = data.userRole.roleName;
      localStorage.setItem(Constants.userId, data.id);
      localStorage.setItem(Constants.token, data.encryptedEmail);
      localStorage.setItem(Constants.name, data.name);
      localStorage.setItem(Constants.email, data.email);
      localStorage.setItem(Constants.userRole, currentRole);
      setAuth({ isloggedIn: true });
      if (
        currentRole === roles.superAdmin ||
        currentRole === roles.ACE_HIGH_ADMIN ||
        currentRole === roles.SEARCHER
      ) {
        redirect("/searchrebate");
      } else {
        redirect("/searchrebate");
      }
    }

    useEffect(()=>{
        if(token){
            getUserDataFromToken({
              variables:{
                token
              },
              onCompleted(data){
                if(data){
                    const getUserDetailsViaToken = data.getUserDetailsViaToken
                    setData(getUserDetailsViaToken);
                }
              }
            })
        }
       
    },[token,getUserDataFromToken])

    const handleFormSubmit = async (values) => {
        const { email, password } = values
        try {
            const res = await userData({
                variables: {
                    email, password
                }
            })
            if (res) {
                const data=res.data.login;
                setData(data);
            }
        } catch (err) {
            setAlertMessage(() => {
              return <Alert type="warning" message={err.message}></Alert>;
            });
            resetState();
        }
    }

    return (
        <Spin spinning={loading}>
        <section>
            <Container className='py-2'>
                <Image fluid src='images/Dahon_Cropped_260x.jpg' className='nav-logo' />
            </Container>
            <Container className='header mb-5'>
                <Row className='p-4 justify-content-center'>
                    <Col md={4} className='header-box'>
                        <Row className='bg-light p-4 border-bottom text-center'>
                            <Col>
                                <h2>Log In</h2>
                            </Col>
                        </Row>
                        <Row className='pt-4 px-4 '>
                            {/* <Col>
                                <p className='text-center'>(Google Sign In)</p>
                            </Col> */}
                        </Row>
                        <Row className='pb-4 px-4 border-bottom'>
                            <Col className='d-flex justify-content-center'>
                                {/* <Button onClick={() => login()}>
                                    Sign in with Google
                                </Button> */}
                            </Col>
                        </Row>
                        <Row className='p-4'>
                            <Col>
                                <p className='text-center fw-bold'>Or sign in with username and password</p>
                                <Form form={form} layout="vertical" name="dynamic_rule" onFinish={(values) => handleFormSubmit(values)}>
                                    <Form.Item className="mb-3" name={'email'} label={'Email'}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'field is required*',
                                            },
                                            {
                                                type: "email",
                                                message: "please enter valid email address",
                                            },
                                        ]}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item className="mb-1" name={'password'} label={'Password'}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'field is required*',
                                            },
                                        ]}>
                                        <Input.Password placeholder="input password" />
                                    </Form.Item>
                                    <a href='/forgotpassword' className='powur-link'>Forgot Password</a>
                                    
                                    <div style={{ height: '30px' }}>{alertMessage}</div>
                                    
                                    <Button type='Submit' className='powur-btn-secondary mt-4 w-100'>
                                        {loading ? 'Logging In...' : 'Log In'}
                                    </Button>
                                </Form>
                                {error && <div className="error-message">{error.message}</div>}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col>

                    </Col>
                </Row>
            </Container>
        </section>
        </Spin>
    );
}

export default LogIn;