import React, { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import {
    Form,
    Col, Row, DatePicker, Button, Select, Alert
} from 'antd';
// import 'antd/dist/antd.css';
import { Container } from "react-bootstrap";
import Navigation from "./Navigation";
import Header from "./Header";
import Footer from "./Footer";
import { rebateStatus } from "../Constants";
import CheckReport from "./reports/CheckReport";


const checkBoxList = [
    {
        value: 'paymentAmount',
        label: 'Payment Amount'

    },
    {
        value: 'bikeModel',
        label: 'Bike Model'

    },
    {
        value: 'bikeSerialNumber',
        label: 'Bike Serial Number'

    },
    {
        value: 'trackingNumber',
        label: 'Tracking Number'

    },
    {
        value: 'customerFirstName',
        label: 'Customer First name'

    },
    {
        value: 'customerLastName',
        label: 'Customer Last name'

    },
    {
        value: 'customerEmail',
        label: 'Customer Email'

    },
    {
        value: 'emailSentDate',
        label: 'Email Sent Date'
    },
    {
        value: 'customerPhoneNumber',
        label: 'Phone'

    },
    {
        value: 'customerAddress1',
        label: 'Customer Address 1'

    },
    {
        value: 'customerAddress2',
        label: 'Customer Address 2'

    },
    {
        value: 'customerCity',
        label: 'Customer City'

    },
    {
        value: 'customerState',
        label: 'Customer State'

    },
    {
        value: 'customerZip',
        label: 'Customer Zip'

    },
    {
        value: 'status',
        label: 'Status'

    },
    {
        value: 'reason',
        label: 'Declined Reason'

    },
    {
        value: 'approvedAtTime',
        label: 'Approved/Declined Date'

    },
    {
        value: 'rewardType',
        label: 'Reward Type'

    },
    {
        value: 'createdAt',
        label: 'Submitted Date'

    },
    {
        value: 'approvedByUser',
        label: 'Approved/Declined By'

    },
    {
        value: 'surveyAnswer1',
        label: 'Survey Question 1'
    },
    {
        value: 'surveyAnswer2',
        label: 'Survey Question 2'
    },
    {
        value: 'surveyAnswer3',
        label: 'Survey Question 3'
    },
    {
        value: 'surveyAnswer4',
        label: 'Survey Question 4'
    },
    {
        value: 'surveyAnswer5',
        label: 'Survey Question 5'
    },
    {
        value: 'referralFirstName',
        label: 'Referral First Name'
    },
    {
        value: 'referralLastName',
        label: 'Referral Last Name'
    },
    {
        value: 'referralEmail',
        label: 'Referral Email'
    },
    {
        value: 'shippedDate',
        label: 'Shipped Date'
    },
    {
        value: 'dateOfSale',
        label: 'Date of Sale',
    },
];


const SEND_REPORT = gql`
    mutation generateReport($filters:[String],$startDate : String , $endDate : String, $status: String ) {
        generateReport(input: {
        filters: $filters,
        startDate: $startDate,
        endDate: $endDate
        status:$status
    }) {
      url
    }
  }
`;
const Reports = (props) => {

    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [paymentTypeArr, setPaymentTypeArrr] = useState([]);
    const { Option } = Select;
    const [selected, setSelected] = useState([]);
    const [status, setStatus] = useState();
    const [alertMessage, setAlertMessage] = useState()
    const [form]=Form.useForm();


    const [sendReport] = useMutation(SEND_REPORT)

    const resetState = () => {
        setTimeout(() => {
            setAlertMessage()
        }, 3000)
    }


    const onFinish = async (values) => {
        try {
            const res = await sendReport({
                variables: {
                    filters: selected,
                    startDate: startDate,
                    endDate: endDate,
                    status: status
                }
            })
            if (res) {
                setAlertMessage(() => {
                    window.scrollTo({ top: 0, left: 0, behaviour: "smooth" });
                    return <Alert type="success" message={`Report Generated Successfully !!`}></Alert>
                })
                resetState();

                setTimeout(() => {
                    window.location.replace(res.data.generateReport.url);
                }, 2000)
                setTimeout(() => {
                    setAlertMessage(() => {
                        window.scrollTo({ top: 0, left: 0, behaviour: "smooth" });
                        return <Alert type="success" message={`Downloaded Successfully !!`}></Alert>
                    })
                    resetState();
                }, 6000)
            }
        } catch (err) {
            setAlertMessage(() => {
                window.scrollTo({ top: 0, left: 0, behaviour: "smooth" });
                return <Alert type="warning" message={err.message}></Alert>
            })
            resetState()

        }



    };

    const startDateCapture = date => {
        if(date != null){
            setStartDate(date.format('YYYY-MM-DD'))
        }
        else{
            setStartDate();
            setEndDate();
            form.resetFields(["startDate","endDate"]);
        }
    }

    const endDateCapture = date => {
        if(date != null){
            setEndDate(date.format('YYYY-MM-DD'))
        }
        else{
            setEndDate();
            setStartDate();
            form.resetFields(["startDate","endDate"]);
        }
    }


    const getSelectedHandler = (data) => {
        setSelected(data)
    }

    const handleChange = (value) => {
        setPaymentTypeArrr(value)
    };


    return (
        <>
            <section className='vh-fill'>
                <Navigation />

                <Container>
                    <div className="alertmessage w-50 mt-2">
                        {alertMessage}
                    </div>
                    <div className="mt-5">
                        <h2>Rebate Reporting</h2>
                        <p>Reports will be produced and downloaded as Excel</p>

                        <Form
                            form={form}
                            name="report"
                            onFinish={onFinish}
                        >
                            <div className="card">
                                <div className="card-body">
                                    <Row>
                                        <Col>
                                            <CheckReport checkBoxList={checkBoxList} selected={getSelectedHandler} />
                                        </Col>
                                    </Row>
                                    <div className="container bg-light p-2 mt-4">
                                        <div className="row">
                                            <div className="col-6 d-flex justify-content-end">
                                                <div className="form-group">
                                                    <Form.Item label="Start Date" value="sdate" name="startDate">
                                                        <DatePicker onChange={startDateCapture} />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-group">
                                                    <Form.Item label="End Date" value="edate" name="endDate">
                                                        <DatePicker onChange={endDateCapture} disabledDate={d => d.isBefore(startDate)} />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-7 mx-auto">
                                                <div className="d-sm-block d-md-flex justify-content-center align-items-center">
                                                    <label >Status</label>
                                                    <div className="d-inline ms-2">
                                                        <Form.Item 
                                                        style={{marginBottom:"0"}}
                                                        name="statustype"
                                                        rules={
                                                            [
                                                                {
                                                                    required: true,
                                                                    message: 'Please select status type',
                                                                },
                                                            ]}
                                                        >

                                                        <Select
                                                            placeholder="select status type"
                                                            onSelect={(e) => { setStatus(e) }}
                                                            optionLabelProp="label"
                                                            className="w-100"
                                                        >
                                                            {
                                                                rebateStatus.map((status) => {
                                                                    return (
                                                                        <>
                                                                            <Option value={status} label={status}>
                                                                                <div className="demo-option-label-item">
                                                                                    {status}
                                                                                </div>
                                                                            </Option>
                                                                        </>
                                                                    )
                                                                })
                                                            }

                                                        </Select>
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <hr />
                                    <br />
                                    <Row>
                                        <Col span={6} offset={10}>
                                            <Button type="primary" htmlType="submit" shape="round" size="large">
                                                Export Report
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Form>
                    </div>
                    <br />
                </Container>
            </section>
            <Footer />
        </>
    );
}

export default Reports;